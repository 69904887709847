<template>
  <div class="broadcast-container">
    <h2>Broadcast Slots</h2>
    <div class="broadcast-slots">
      <div class="row">
        <div class="col-12">
          <p class="small text-white">
            {{ label }}: {{ influencerTimeZone | timezoneAbbreviation }}
          </p>
        </div>
        <div class="col-12 col-sm-6">
          <b-input-group >
            <label>Date</label>
            <datepicker class="date-picker w-100"
                        name="slotDate"
                        calendar-class="date-calendar"
                        input-class="date-input w-100"
                        placeholder="Pick date"
                        v-model="pickedDate"
                        wrapper-class="date-picker">
            </datepicker>
          </b-input-group>
        </div>
        <div class="col-12 col-sm-6">
          <b-input-group>
            <label>Timezone</label>
            <b-form-select class="timezone-select form-control w-100"
                           placeholder="Time Zone"
                           :options="timezones"
                           size="sm"
                           v-model="timezone">
            </b-form-select>
          </b-input-group>
        </div>
      </div>

      <!-- Broadcast Slots -->
      <div v-show="!loading" class="mt-3">
        <div class="row" v-if="slots != ''">
          <div class="col-12 col-sm-6 col-md-3 d-flex"
               v-for="(slot, index) in slots"
              :key="index">
            <div class="slot"
                 v-if="userType === 5 || slot.status === 'reserved'"
                 style="width: 100%;">
              <p class="small slot-status"
                 :class="slot.status">
                <b>{{ slot.status }}</b>
              </p>
              <p class="small mb-0">
                Start Time:
                <pre>{{slot.start_time | moment('timezone', timezone,
                  'hh:mm a z')}}</pre>
              </p>
              <p class="small mb-3">
                End Time:
                <pre>{{slot.end_time | moment('timezone', timezone,
                  'hh:mm a z')}}</pre>
              </p>
              <p class="small mb-0"
                 v-if="slot.status === 'reserved'">
                Educator: {{ slot.firstname }} {{ slot.lastname }}
              </p>
              <p class="small mb-0"
                 v-if="slot.status === 'reserved' && slot.school !== null">
                School: {{ slot.school | truncate(25) }}
              </p>
            </div>

            <div class="slot clickable"
                 @click="confirmReservation(slot)"
                 v-else>
              <p class="small slot-status"
                 :class="slot.status">
                <b> {{ slot.status }} </b>
              </p>
              <p class="small mb-0">
                Start Time:
                <pre>{{slot.start_time | moment('timezone', timezone,
                  'hh:mm a z')}}</pre>
              </p>
              <p class="small mb-3">
                End Time:
                <pre>{{ slot.end_time | moment('timezone', timezone,
                  'hh:mm a z')}}</pre>
              </p>
            </div>
          </div>
        </div>

        <!-- No Slots -->
        <div class="row" v-else>
          <div class="col">
            <p class="text-white text-center">No Slots Available</p>
          </div>
        </div>
      </div>

    </div>

    <!-- Loading -->
    <loading :active.sync="loading"
              :is-full-page="true"
              background-color="#000"
              color="#068bcd"
              :opacity="0.5"
              :width="70"
              :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';
  import Datepicker from 'vuejs-datepicker';
  import moment from 'moment-timezone';
  
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  
  export default {
    name       : 'BroadcastSlots',
    components : {
      Datepicker,
      Loading,
      RainbowLoader,
    },
    props : {
      influencer : Number,
      tz         : String,
      userType   : Number,
    },
    data() {
      return {
        pickedDate : null,
        timezone   : 'America/Chicago',
        timezones  : [ {
          value : 'America/New_York',
          text  : 'Eastern Standard Time',
        }, {
          value : 'America/Chicago',
          text  : 'Central Standard Time',
        }, {
          value : 'America/Denver',
          text  : 'Mountain Standard Time',
        }, {
          value : 'America/Phoenix',
          text  : 'Mountain no DST',
        }, {
          value : 'America/Los_Angeles',
          text  : 'Pacific Standard Time',
        }, {
          value : 'America/Anchorage',
          text  : 'Alaska Standard Time',
        }, {
          value : 'America/Adak',
          text  : 'Hawaii Standard Time',
        }, {
          value : 'Pacific/Honolulu',
          text  : 'Hawaii no DST',
        } ],
        slots   : [],
        loading : false,
        user    : this.$store.getters['user/user'],
      }
    },
    mounted() {
      // Get Default Date
      this.getDefaultDate();
    },
    computed : {
      // eslint-disable-next-line vue/return-in-computed-property
      label() {
        switch(this.userType) {
        case 3: // Educator
        case 4: // Champion
          return "Influencer's Time Zone";
        case 5: // Influencer
          return "My Time Zone";
        default:
          break;
        }
      },
      // eslint-disable-next-line vue/return-in-computed-property
      influencerTimeZone() {
        switch (this.userType) {
        case 3: // Educator
        case 4: // Champion
          return this.tz;
        case 5: // Influencer
          return this.$store.getters['user/user'].timezone;
        default:
          break;
        }
      },
      userTimeZone() {
        return this.$store.getters['user/user'].timezone;
      },
    },
    watch : {
      pickedDate(value) {
        this.getBroadcastSlots(value, this.influencer);
      },
    },
    methods : {

      /**
       * Get Default Date
       */
      getDefaultDate() {
        const today = new Date();
        this.pickedDate = (this.user.user_type === 5) ?
          today : new Date(today).setDate(today.getDate()+1);
      },

      /**
       * Get Broadcast Slots
       * @param value Picked date object
       * @param id Influencer's ID
       */
      getBroadcastSlots(value, id) {
        this.loading   = true;
        const pickedDate = moment(value).format('YYYY-MM-DD');

        this.$http.get('api/broadcast/slot/day', {
          params : {
            day          : pickedDate,
            influencerId : id,
            timezone     : this.tz,
          },
        }).then(response => {
          this.loading = false;
          const slots    = response.data;

          // convert time (stored as UTC in the database) to user time zone
          for (let i=0; i<slots.length; i++) {
            const start = moment.utc(slots[i].start_time);
            const end   = moment.utc(slots[i].end_time);

            // eslint-disable-next-line camelcase
            slots[i].start_time = start.tz(this.user.timezone).format();
            // eslint-disable-next-line camelcase
            slots[i].end_time = end.tz(this.user.timezone).format();
          }
          this.slots = slots;
        }).catch(() => {
          this.loading = false;
          this.slots   = [];
        });
      },

      /**
       * Confirm Reservation
       * @param slot Selected slot's data
       */
      confirmReservation(slot) {
        const start = moment(slot.start_time).tz(this.timezone)
          .format('hh:mm a z');
        const end   = moment(slot.end_time).tz(this.timezone)
          .format('hh:mm a z');
        const date  = moment(slot.start_time).tz(this.timezone)
          .format('MMMM DD, YYYY');

        this.$modal.show('dialog', {
          title : "Slot Reservation",
          text  : "You have picked a slot that starts at <b>" + start +
            "</b> and ends at <b>" + end + "</b> on <b>" + date +
            "</b>. Do you want to reserve?",
          buttons : [ {
            title   : "Yes",
            handler : () => {
              this.$http.put('api/broadcast/slot/reserve', {
                "status"           : 'reserved',
                "educatorId"       : this.user.user_id,
                "broadcastSlotId"  : slot.broadcast_slot_id,
                "educatorTimezone" : this.user.timezone,
              }).then(() => {
                // Refresh broadcast slot list
                this.getBroadcastSlots(this.pickedDate, this.influencer);
                this.$modal.hide('dialog');
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },
    },
    mixins : [
      FilterMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/profile/broadcast-slots";
</style>
